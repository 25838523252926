import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {get, del, post} from '../../helpers/api_helper'

export const fetchAllPlan = createAsyncThunk('admin-fetch-all-plans', async (query) => {
  const response = await get(`/admin/fetch-all-plans${query}`)
  return response
})
export const fetchAllMenu = createAsyncThunk('fetch-all-menus', async (id) => {
  const response = await get(`/admin/fetch-all-menus?id=${id}`)
  return response
})
export const deleteAdminPlan = createAsyncThunk('admin-delete-plan', async (payload) => {
  return await del(`/admin/delete-plan/${payload}`)
})
export const updateCompanyPermission = createAsyncThunk('company-updated', async (data) => {
  return await post('/admin/update-company-permission-based-on-updated-plan', data)
})
export const addUpdatePlan = createAsyncThunk('add-update-plan', async (data) => {
  return await post('/admin/create-update-plan', data)
})
export const fetchInvoiceList = createAsyncThunk('admin-fetch-invoice-list', async () => {
  const response = await get('/admin/fetch-invoice-list')
  return response
})

const activitySlice = createSlice({
  name: 'activity',
  initialState: {},
  reducers: {},
  extraReducers () {}
})

export default activitySlice.reducer