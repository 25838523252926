import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { Container, Row, Col, Button, Offcanvas, Spinner, Card, CardBody } from 'reactstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { fetchInvoiceList } from '../../store/plan'
import BreadCrumb from '../../Components/Common/BreadCrumb'

export default function InvoiceList () {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [invoiceList, setInvoiceList] = useState([])
  const [count, setCount] = useState(0)

  const fetchAllInvoices = async () => {
    try {
      setLoading(true)
      const response = await dispatch(fetchInvoiceList()).unwrap()
      if (response?.status?.success) {
        setInvoiceList(response.data.invoiceList)
        setCount(response.data.count)
      }
    } catch (error) {
      toast.error(error.status.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllInvoices()
  }, [])

  const columplan = [
    {
      dataField: 'company_name',
      text: 'Invoice No',
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row?.invoice_no}

            </div>
          </div>
        )
      }

    },
    {
      dataField: 'company_name',
      text: 'Company Name',
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row?.company?.name}

            </div>
          </div>
        )
      }

    },
    {
      dataField: 'date_created',
      text: 'Date',
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row?.created_at ? moment(row.created_at).format('Do, MMM, YYYY') : 'N/A'}
              {/* 10th, Jan, 2025 */}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'number_of_guards',
      text: 'Amount',
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row?.transaction_history?.amount}
            </div>
          </div>
        )
      }
    }
  ]

  // date, company name, invoice no, Date, amount

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div>
            <Row>
              <Col>
                <BreadCrumb title="Invoice" />
              </Col>
            </Row>
          </div>
          <Row className='mt-2'>
            <Col xl={12}>
              <BootstrapTable
                classes="react-bootstrap-table background-table"
                keyField="id"
                pagination={paginationFactory({sizePerPageList: [10, 20, 50],
                  showTotal: true,
                  totalSize: count,
                  alwaysShowAllBtns: true})}
                data={invoiceList}
                columns={columplan}
                bordered={false}
                striped={false}
                headerClasses={'table_head_background_color table_background'}
                rowClasses={
                  'font-family font_size_4 color_main_text table_border_bottom'
                }
                responsive
                noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}