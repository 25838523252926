import React from 'react'
import { Navigate } from 'react-router-dom'

//Dashboard
import DashboardEcommerce from '../pages/DashboardEcommerce'
import Settings from '../pages/Settings'
import List from '../pages/Companies/components/layout.js'
import Reports from '../pages/Reports'
import OnBoarding from '../pages/OnBoardingCheckList'
//login
import Login from '../pages/Authentication/Login'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'

import Plans from '../pages/plans'
//compdetails
// import CompanyDetails from "../pages/Companies/details/components/CompanyDetails"
// User Profile
import UserProfile from '../pages/Authentication/user-profile'
import InvoiceList from '../pages/Invoice/InvoiceList.js'

const authProtectedRoutes = [

  { path: '/dashboard', component: <DashboardEcommerce /> },
  { path: '/settings', component: <Settings /> },
  { path: '/reports', component: <Reports /> },
  { path: '/index', component: <DashboardEcommerce /> },
  { path: '/companies_list/:type', component: <List /> },
  //{ path: '/on_boarding_checklist', component: <OnBoarding /> },
  // { path: "/company-details/:id", component: <CompanyDetails /> },
  // { path: "/companies_list/:type/details/:id", component: <CompanyDetails /> },
  { path: '/plan', component: <Plans /> },
  { path: '/invoice', component: <InvoiceList /> },
  //User Profile
  { path: '/profile', component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to="/dashboard" />
  },
  { path: '*', component: <Navigate to="/dashboard" /> }
]

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/forgot-password', component: <ForgetPasswordPage /> },
  { path: '/register', component: <Register /> }
]

export { authProtectedRoutes, publicRoutes }